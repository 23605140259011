Mosaic.addPlugins([require('/var/www/html/app/design/frontend/PlugAndSell2/pwa/packages/contractors-merchant-limit/src/plugin/ContractorsReducer.plugin.ts'),require('/var/www/html/app/design/frontend/PlugAndSell2/pwa/packages/contractors-contractor/src/plugin/ContractorsReducer.plugin.ts')]);
import { Reducer } from 'redux';
import { ContractorsAction, ContractorsActionType, ContractorsReducerStore } from './Contractors.type';

import { initialStateExtension as contractorStateExtansion } from '../../../../contractors-contractor/src/store/Contractors/Contractors.reducer'
import { initialStateExtension as merchantLimitStateExtansion } from '../../../../contractors-merchant-limit/src/store/Contractors/Contractors.reducer'

export const initialState: ContractorsReducerStore = {
    isLoading: false,
    ...contractorStateExtansion,
    ...merchantLimitStateExtansion
};

/** @namespace ContractorsPlugin/Store/Contractors/Reducer/ContractorsReducer */
export const ContractorsReducer: Reducer<ContractorsReducerStore, ContractorsAction> = (state: ContractorsReducerStore = initialState, action) => {
  switch(action.type) {
    case ContractorsActionType.UPDATE_LOADING:
      return ({
        ...state,
        isLoading: action.payload
      })
    default: 
      return state;
  }
};

export default ContractorsReducer;
