Mosaic.addPlugins([require('/var/www/html/app/design/frontend/PlugAndSell2/pwa/packages/opengraph-ts/src/plugin/MetaStore.plugin.ts')]);
import { Reducer } from 'redux';

import { MetaActionType, MetaStore, PageMeta, UpdateMetaAction } from './Meta.type';

export const updateEveryTime: Array<keyof PageMeta> = ['title', 'description', 'keywords', 'canonical_url', 'robots', 'status_code'];

/** @namespace PlugAndSell2/Store/Meta/Reducer/filterData */
export const filterData = (data: Partial<PageMeta>): Partial<PageMeta> => {
    const updated = updateEveryTime.reduce((acc: Partial<PageMeta>, key) => {
        acc[key] = data[key];

        return acc;
    }, {});

    return { ...data, ...updated };
};

/** @namespace PlugAndSell2/Store/Meta/Reducer/getInitialState */
export const getInitialState = (): MetaStore => ({
    title: '',
    title_prefix: '',
    title_suffix: '',
    description: '',
    keywords: '',
    canonical_url: '',
    robots: '',
    status_code: '',
});

/** @namespace PlugAndSell2/Store/Meta/Reducer/MetaReducer */
export const MetaReducer: Reducer<MetaStore, UpdateMetaAction> = (state = getInitialState(), action) => {
    const { payload = {}, type } = action;

    switch (type) {
        case MetaActionType.UPDATE_META:
            const filteredData = filterData(payload);

            return {
                ...state,
                ...filteredData,
            };

        default:
            return state;
    }
};

export default MetaReducer;
